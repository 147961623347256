<template>
  <section
    class="w-4/6 mx-auto text-secondary flex min-h-[50vh] mb-16 transition-colors duration-800 xl:w-11/12 sm:w-full px-4"
    v-colors.dark="{ primary: '#7939FF', secondary: '#FDB9DD' }"
  >
    <div class="flex flex-col my-auto w-full">
      <div class="flex flex-col justify-center items-center gap-8 mt-16">
        <h1 ref="target" class="leading-tight sm:text-3xl md:text-center">
          <span class="md:hidden">
            {{ $t('home.claim.decent') }}
            <span class="h-[1.1em] overflow-hidden inline-block align-top">
              <span class="inline-flex flex-col slide">
                <span>{{ $t('home.claim.cars') }}</span>
                <span>{{ $t('home.claim.prices') }}</span>
                <span>{{ $t('home.claim.cars') }}</span>
              </span>
            </span>
            <br />
          </span>

          <span>
            {{ $t('home.claim.decent2') }}
            <span class="text-orange h-[1.1em] overflow-hidden inline-block align-top md:block">
              <span class="inline-flex flex-col slide" style="animation-delay: 0.1s">
                <span>{{ $t('home.claim.prices') }}</span>
                <span>{{ $t('home.claim.cars') }}</span>
                <span>{{ $t('home.claim.prices') }}</span>
              </span>
            </span>
          </span>
        </h1>
        <!-- <AppSearch class="mt-8 w-2/4 max-w-md min-w-[21rem] sm:mt-4" /> -->
        <AppSearch />
      </div>
    </div>
  </section>
  <section
    class="mb-48 w-4/6 text-secondary mx-auto xl:w-11/12 lg:me-0 lg:lg:w-[95.83333333%] sm:w-full"
    v-colors.dark="{ primary: '#7939FF', secondary: '#FDB9DD' }"
  >
    <div class="w-full mt-auto">
      <VehicleAdvancedFilter chipColor="pink" bg-color="purple">
        <NuxtLink
          :to="localePath({ name: 'cars' })"
          class="text-sm py-3 no-underline ml-auto pl-4 flex-shrink-0"
        >
          {{ $t('home.filter.view-all') }}
        </NuxtLink>
      </VehicleAdvancedFilter>
      <UiDragscroll
        class="flex items-start gap-4 w-full overflow-y-visible pb-4 pt-2 px-4 lg:overflow-auto"
      >
        <AppCarCard
          v-if="filteredVehicles && filteredVehicles.length > 0"
          class="lg:min-w-[10rem] max-w-xs"
          v-for="car in filteredVehicles?.slice(0, 5)"
          :car="car"
          :key="car.id"
          :isCardSm="true"
          :duration="bookingDuration"
          :discount="discount ?? 1"
        >
        </AppCarCard>
        <div
          v-if="filteredVehicles && filteredVehicles.length < 1"
          class="min-h-[10em] w-full flex justify-center items-center"
        >
          <p class="text-center text-balance">{{ $t('home.filter.no-matches') }}</p>
        </div>
      </UiDragscroll>
    </div>
  </section>
  <section
    class="w-4/6 mx-auto text-secondary transition-colors duration-800 xl:w-11/12 sm:w-full px-4"
  >
    <div class="mb-64" v-colors="{ primary: '#FBD302', secondary: '#7939FF' }">
      <h2 class="sm:text-2xl">{{ $t('home.why.title') }}</h2>
      <div class="my-28">
        <div class="flex gap-4 my-20 md:flex-col">
          <em class="w-1/12 md:w-full text-3xl font-medium not-italic">1.</em>
          <h3 class="w-5/12 md:w-full">{{ $t('home.why.cheap.leading') }}</h3>
          <p class="w-5/12 grow text-lg md:w-full">
            {{ $t('home.why.cheap.content') }}
          </p>
        </div>
        <div class="flex gap-4 my-20 md:flex-col">
          <em class="w-1/12 md:w-full text-3xl font-medium not-italic">2.</em>
          <h3 class="w-5/12 md:w-full">{{ $t('home.why.all-inclusive.leading') }}</h3>
          <p class="w-5/12 grow text-lg md:w-full">
            {{ $t('home.why.all-inclusive.content') }}
          </p>
        </div>
        <div class="flex gap-4 my-20 md:flex-col">
          <em class="w-1/12 md:w-full text-3xl font-medium not-italic">3.</em>
          <h3 class="w-5/12 md:w-full">{{ $t('home.why.unlimited.leading') }}</h3>
          <p class="w-5/12 grow text-lg md:w-full">
            {{ $t('home.why.unlimited.content') }}
          </p>
        </div>
      </div>
    </div>
    <div class="mb-64" v-colors="{ primary: '#FDB9DD', secondary: '#7939FF' }">
      <h2 class="sm:text-2xl">{{ $t('home.how.title') }}</h2>
      <div class="mt-28 flex gap-8 md:flex-col">
        <div class="basis-0 grow">
          <h2 class="text-3xl">1.</h2>
          <h3 class="">{{ $t('home.how.select') }}</h3>
        </div>
        <div class="basis-0 grow">
          <h2 class="text-3xl">2.</h2>
          <h3 class="">{{ $t('home.how.drive') }}</h3>
        </div>
        <div class="basis-0 grow">
          <h2 class="text-3xl">3.</h2>
          <h3 class="">{{ $t('home.how.return') }}</h3>
        </div>
      </div>
      <img class="w-full md:mt-16" src="/img/arrow.svg" alt="" />
      <NuxtLink
        class="inline-block text-center mt-4 px-12 py-4 bg-purple text-pink no-underline rounded-s text-base hover:scale-[.98] transition-transform"
        :to="localePath({ name: 'how' })"
        >{{ $t('home.how.cta') }}</NuxtLink
      >
    </div>
    <div class="mb-64" v-colors.dark="{ primary: '#7939FF', secondary: '#FBD302' }">
      <h2 class="sm:text-2xl">{{ $t('home.facts.title') }}</h2>
      <div class="grid gap-4 grid-cols-2 mt-12 sm:grid-cols-1">
        <div
          class="flex flex-col justify-center h-96 bg-yellow text-purple text-center px-8 rounded-l"
        >
          <p class="text-4xl font-wide">{{ $t('home.facts.rating.value') }}</p>
          <p class="text-lg">{{ $t('home.facts.rating.text') }}</p>
        </div>
        <div
          class="flex flex-col justify-center h-96 bg-yellow text-purple text-center px-8 rounded-l"
        >
          <p class="text-4xl font-wide">{{ $t('home.facts.bookings.value') }}</p>
          <p class="text-lg">{{ $t('home.facts.bookings.text') }}</p>
        </div>
        <div
          class="flex flex-col justify-center h-96 bg-yellow text-purple text-center px-8 rounded-l"
        >
          <img class="w-16 mx-auto mb-4" src="/img/flag.svg" alt="Swiss Flag" />
          <p class="text-lg">
            {{ $t('home.facts.swiss.text') }}
          </p>
        </div>
        <div
          class="flex flex-col justify-center h-96 bg-yellow text-purple text-center px-8 rounded-l"
        >
          <div class="flex mx-auto mb-4">
            <img
              class="w-20 h-20 object-cover rounded-full -mx-1 -rotate-6"
              src="/img/portraits/david-small.jpg"
              alt="Portrait von David"
            />
            <img
              class="w-20 h-20 object-cover rounded-full -mx-1 rotate-6 outline outline-8 outline-yellow"
              src="/img/portraits/lukas-small.jpg"
              alt="Portrait von Lukas"
            />
          </div>
          <p class="text-lg">{{ $t('home.facts.team.text') }}</p>
        </div>
      </div>
    </div>
    <div class="mb-64 text-center">
      <p class="text-xl font-wide uppercase text-center sm:text-base">
        {{ $t('home.end.text') }}
      </p>
      <NuxtLink
        class="inline-block text-center mt-4 px-12 py-4 bg-yellow text-purple no-underline rounded-s text-base hover:scale-[.98] transition-transform"
        :to="localePath({ name: 'cars' })"
        >{{ $t('home.end.cta') }}</NuxtLink
      >
    </div>
  </section>
</template>

<script setup lang="ts">
definePageMeta({
  layout: 'edgeless',
});

const { t } = useI18n();
const localePath = useLocalePath();

useSeoMeta({
  title: t('seo-meta.home.title'),
  ogTitle: t('seo-meta.home.title'),
  description: t('seo-meta.home.description'),
  ogDescription: t('seo-meta.home.description'),
});

const { getFiltered } = useVehicles();

const {
  startDate,
  endDate,
  vehicleTypes,
  bookingDuration,
  allWheelFilter,
  fuelTypeFilter,
  transmissionTypeFilter,
  getGlobalDiscount,
} = useSearch();
const discount = await getGlobalDiscount();
const { data: filteredVehicles } = await getFiltered({
  availableFrom: startDate,
  availableUntil: endDate,
  vehicleType: vehicleTypes,
  allWheel: allWheelFilter,
  fuelType: fuelTypeFilter,
  transmissionType: transmissionTypeFilter,
});
</script>

<style scoped>
.slide {
  /* animation: slide 7s cubic-bezier(0.5, 1.75, 0.56, 0.92) infinite; */
  /* animation: slide 7s cubic-bezier(0.24, 1.8, 0.31, 0.82) infinite; */
  /* animation: slide 7s cubic-bezier(0.2, 1.73, 0.29, 0.87) infinite; */
  /* animation: slide 7s cubic-bezier(0.24, 1.73, 0.34, 0.87) infinite; */
  animation: slide 7s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}

@keyframes slide {
  0% {
    transform: translateY(0%);
  }

  30% {
    transform: translateY(0%);
  }

  45% {
    transform: translateY(-33.33%);
  }

  85% {
    transform: translateY(-33.33%);
  }

  100% {
    transform: translateY(-66.66%);
  }
}
</style>
